import React from "react";
import "./Footer.css";

const Footer = () => (
  <section
    data-bs-version="5.1"
    class="footer4 cid-ubtcR6XhWT"
    once="footers"
    id="footer-4-ubtcR6XhWT"
  >
    <div class="container">
      <div class="media-container-row align-center mbr-white">
        <div class="col-12">
          <p class="mbr-text mb-0 mbr-fonts-style display-7">
            © 2024 MoreMonths. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </section>
  // <footer className="footer">
  //   <p>© 2024 MoreMonths. All rights reserved.</p>
  // </footer>
);

export default Footer;
