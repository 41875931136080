import React, { useState } from "react";
import logo from "../../assets/logos/Logomakerproject.png";
import "./Header.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the state of the navbar
  const toggleNavbar = () => setIsOpen(!isOpen);

  // Function to close the navbar when a link is clicked
  const closeNavbar = () => setIsOpen(false);
  return (
    <section
      data-bs-version="5.1"
      className="menu menu2 cid-ubtcR6Qwbn"
      once="menu"
      id="menu-5-ubtcR6Qwbn"
    >
      <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
        <div className="container">
          <div className="navbar-brand">
            <span className="navbar-logo" style={{ paddingLeft: "2rem" }}>
              {/* <a href="https://getmoremonths.com"> */}
              <a href="#the-promise">
                <img
                  src={logo}
                  alt="MoreMonths Logo"
                  style={{ height: "4.53rem", minWidth: "8rem" }}
                />
              </a>
            </span>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarNavAltMarkup"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
              <li className="nav-item">
                <a
                  className="nav-link link text-black display-4"
                  href="#the-problem"
                  onClick={closeNavbar}
                >
                  The Problem
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link link text-black display-4"
                  href="#our-solution"
                  onClick={closeNavbar}
                >
                  Our Solution
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link link text-black display-4"
                  href="#benefits"
                  onClick={closeNavbar}
                >
                  Benefits
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link link text-black display-4"
                  href="#about-us"
                  onClick={closeNavbar}
                >
                  About Us
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link link text-black display-4"
                  href="#contact-form"
                  onClick={closeNavbar}
                >
                  Learn More
                </a>
              </li>
            </ul>
            <div className="navbar-buttons mbr-section-btn">
              <a
                className="btn btn-primary display-4"
                href="#contact-form"
                onClick={closeNavbar}
              >
                Join the beta
              </a>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Header;
