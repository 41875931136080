import React, { useState, useEffect } from "react";
import yoga from "../../assets/images/crowded-yoga.jpg";
import yogaClass from "../../assets/images/yoga-class.jpg";
import emailjs from "emailjs-com";
import "./MainContent.css";
// import ContactForm from "../ContactForm"; // Assuming ContactForm is another component

function MainContent() {
  const [formState, setFormState] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID), []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
          setFormState({
            from_name: "",
            from_email: "",
            message: "",
          });
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send the message, please try again.");
        }
      );
  };

  return (
    <div>
      {" "}
      <section
        data-bs-version="5.1"
        class="header18 cid-ubtcR6SV96 mbr-fullscreen"
        id="the-promise"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="content-wrap col-12 col-md-12">
              <h1 class="mbr-section-title mbr-fonts-style mbr-white mb-4 display-1">
                <strong>
                  Retain your monthly fitness subscribers by rewarding loyalty.
                </strong>
              </h1>

              <p class="mbr-fonts-style mbr-text mbr-white mb-4 display-7">
                Built to integrate with Mindbody Software. Bringing on beta
                customers now.
              </p>
              <div class="mbr-section-btn">
                <a class="btn btn-white-outline display-7" href="#contact-form">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        data-bs-version="5.1"
        class="article2 cid-ubtcR6S0vp"
        id="the-problem"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-6 image-wrapper">
              <img class="w-100" src={yoga} alt="Yoga class" />
            </div>
            <div class="col-12 col-md-12 col-lg">
              <div class="text-wrapper align-left">
                <h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
                  <strong>Addressing Membership Churn</strong>
                </h1>
                <p class="mbr-text align-left mbr-fonts-style mb-3 display-7">
                  Every year, fitness studios face a significant challenge: they
                  lose about 30% to 40% of their members. This turnover doesn't
                  just affect revenue—it pulls attention away from the core
                  mission of promoting health and wellness within their
                  community.
                </p>

                <p class="mbr-text align-left mbr-fonts-style mb-3 display-7">
                  Acquiring new members is costly and time-consuming. MoreMonths
                  helps reduce this churn, allowing you to focus on enhancing
                  member experiences and retaining your fitness community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        data-bs-version="5.1"
        class="features023 cid-ubtcR6UDQR pt-0"
        id="metrics-1-ubtcR6UDQR"
      >
        <div class="container">
          <div class="row content-row justify-content-center">
            <h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
              <strong>Current State of Affairs</strong>
            </h1>
            <div class="item features-without-image col-12 col-md-6 col-lg-4 item-mb">
              <div class="item-wrapper">
                <div class="title mb-2 mb-md-3">
                  <span class="num mbr-fonts-style display-1">
                    <strong>50%</strong>
                  </span>
                </div>
                <h4 class="card-title mbr-fonts-style display-5">
                  <strong>Of New Members Quit Within 6 Months</strong>
                </h4>
              </div>
            </div>
            <div class="item features-without-image col-12 col-md-6 col-lg-4 item-mb">
              <div class="item-wrapper">
                <div class="title mb-2 mb-md-3">
                  <span class="num mbr-fonts-style display-1">
                    <strong>63%</strong>
                  </span>
                </div>
                <h4 class="card-title mbr-fonts-style display-5">
                  <strong>Attend regularly in the 1st Month</strong>
                </h4>
              </div>
            </div>
            <div class="item features-without-image col-12 col-md-6 col-lg-4 item-mb">
              <div class="item-wrapper">
                <div class="title mb-2 mb-md-3">
                  <span class="num mbr-fonts-style display-1">
                    <strong>33%</strong>
                  </span>
                </div>
                <h4 class="card-title mbr-fonts-style display-5">
                  <strong>Attend Regularly in the 6th Month</strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        data-bs-version="5.1"
        class="article12 cid-ubtcR6XRYA"
        id="our-solution"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
              <h3 class="mbr-section-title mbr-fonts-style mb-4 mt-0 display-2">
                <strong>Focus on What Matters with MoreMonths</strong>
              </h3>

              <p class="mbr-text mbr-fonts-style display-7">
                We've all seen the usual cancellation story: a member doesn't
                visit often, misses out on the benefits, and decides to quit.
                MoreMonths changes that narrative.
                <br />
                <br />
                By rewarding attendance with targeted incentives, MoreMonths
                reduces cancellations, easing your stress about member loss and
                freeing up more time for you to add value to your community.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section
        data-bs-version="5.1"
        class="article12 cid-ubtcR6S0vp"
        id="benefits"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-6">
                  <h3 class="mbr-section-title mbr-fonts-style mb-4 mt-0 display-2">
                    <strong>Proactively Re-Engage At-Risk Members</strong>
                  </h3>
                </div>
                <div class="col-lg-6">
                  <h3 class="mbr-section-title mbr-fonts-style mb-4 mt-0 display-2">
                    <strong>Foster Lasting Member Habits</strong>
                  </h3>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-6">
                  <p class="mbr-text mbr-fonts-style display-7">
                    MoreMonths automatically identifies members who are likely
                    to cancel their subscriptions. By pinpointing these at-risk
                    members early, our platform enables you to proactively
                    engage them with incentives. This targeted approach not only
                    enhances their motivation to return but also reinforces
                    their connection to your fitness community, dramatically
                    improving retention rates.
                  </p>
                </div>
                <div class="col-lg-6">
                  <p class="mbr-text mbr-fonts-style display-7">
                    Establishing consistent attendance habits in new members is
                    crucial for long-term retention. MoreMonths helps integrate
                    gym visits into the routines of new members through
                    strategic rewards. By incentivizing regular attendance from
                    the start, our tool ensures that new members develop a
                    lasting habit, boosting their overall health and long-term
                    engagement with your studio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section
        data-bs-version="5.1"
        class="article12 cid-ubtcR6XRYA"
        id="benefits"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-6">
              <h3 class="mbr-section-title mbr-fonts-style mb-4 mt-0 display-2">
                <strong> Re-Engage At-Risk Members</strong>
              </h3>
              <p class="mbr-text mbr-fonts-style display-7">
                MoreMonths automatically identifies members at risk of canceling
                and helps you engage them with tailored incentives. This
                proactive approach boosts their motivation to return and
                increases the odds of renewal by 14%.
              </p>
            </div>

            <div class="col-md-12 col-lg-6">
              <h3 class="mbr-section-title mbr-fonts-style mb-4 mt-0 display-2">
                <strong>Foster Lasting Member Habits</strong>
              </h3>
              <p class="mbr-text mbr-fonts-style display-7">
                New members who visit over 7 times a month are 19% more likely
                to renew their membership. MoreMonths, incentivizes new members
                to visit more often by applying credits to their next renewal
                payment based on attendance adherence.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        data-bs-version="5.1"
        class="article2 cid-ubtcR6S0vp"
        id="about-us"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg">
              <div class="text-wrapper align-left">
                <h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
                  <strong>Who We Are</strong>
                </h1>
                <p class="mbr-text align-left mbr-fonts-style mb-3 display-7">
                  MoreMonths is a simple solution for fitness studios looking to
                  reduce their monthly membership churn rate.
                </p>

                <p class="mbr-text align-left mbr-fonts-style mb-3 display-7">
                  We integrate with Mindbody to track your members visits,
                  credit their accounts based on meeting the required visit
                  count you set and remind them to visit to earn the credit.
                </p>

                <p class="mbr-text align-left mbr-fonts-style mb-3 display-7">
                  {" "}
                  MoreMonths does this automatically and can be set up in under
                  10 minutes.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 image-wrapper">
              <img class="w-100" src={yogaClass} alt="Yoga class" />
            </div>
          </div>
        </div>
      </section>
      {/* <section
        data-bs-version="5.1"
        class="list1 cid-ubtcR6UC0d"
        id="faq-1-ubtcR6UC0d"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-10 m-auto">
              <div class="content">
                <div class="row justify-content-center mb-5">
                  <div class="col-12 content-head">
                    <div class="mbr-section-head">
                      <h4 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                        <strong>Burning Questions</strong>
                      </h4>
                    </div>
                  </div>
                </div>
                <div
                  id="bootstrap-accordion_5"
                  class="panel-group accordionStyles accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="card">
                    <div class="card-header" role="tab" id="headingOne">
                      <a
                        role="button"
                        class="panel-title collapsed"
                        data-toggle="collapse"
                        data-bs-toggle="collapse"
                        data-core=""
                        href="#collapse1_5"
                        aria-expanded="false"
                        aria-controls="collapse1"
                      >
                        <h6 class="panel-title-edit mbr-semibold mbr-fonts-style mb-0 display-5">
                          How do we help?
                        </h6>
                        <span class="sign mbr-iconfont mobi-mbri-arrow-down"></span>
                      </a>
                    </div>
                    <div
                      id="collapse1_5"
                      class="panel-collapse noScroll collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                      data-bs-parent="#bootstrap-accordion_5"
                    >
                      <div class="panel-body">
                        <p class="mbr-fonts-style panel-text display-7">
                          MoreMonths revolutionizes fitness studios by boosting
                          subscriber retention through Mindbody integration.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" role="tab" id="headingOne">
                      <a
                        role="button"
                        class="panel-title collapsed"
                        data-toggle="collapse"
                        data-bs-toggle="collapse"
                        data-core=""
                        href="#collapse2_5"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                        <h6 class="panel-title-edit mbr-semibold mbr-fonts-style mb-0 display-5">
                          Why choose us?
                        </h6>
                        <span class="sign mbr-iconfont mobi-mbri-arrow-down"></span>
                      </a>
                    </div>
                    <div
                      id="collapse2_5"
                      class="panel-collapse noScroll collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                      data-bs-parent="#bootstrap-accordion_5"
                    >
                      <div class="panel-body">
                        <p class="mbr-fonts-style panel-text display-7">
                          We turn fitness into a lifestyle, motivating customers
                          to sweat it out more often.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" role="tab" id="headingOne">
                      <a
                        role="button"
                        class="panel-title collapsed"
                        data-toggle="collapse"
                        data-bs-toggle="collapse"
                        data-core=""
                        href="#collapse3_5"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        <h6 class="panel-title-edit mbr-semibold mbr-fonts-style mb-0 display-5">
                          Who benefits?
                        </h6>
                        <span class="sign mbr-iconfont mobi-mbri-arrow-down"></span>
                      </a>
                    </div>
                    <div
                      id="collapse3_5"
                      class="panel-collapse noScroll collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                      data-bs-parent="#bootstrap-accordion_5"
                    >
                      <div class="panel-body">
                        <p class="mbr-fonts-style panel-text display-7">
                          Fitness studios seeking to keep their clients engaged
                          and committed to their health goals.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" role="tab" id="headingOne">
                      <a
                        role="button"
                        class="panel-title collapsed"
                        data-toggle="collapse"
                        data-bs-toggle="collapse"
                        data-core=""
                        href="#collapse4_5"
                        aria-expanded="false"
                        aria-controls="collapse4"
                      >
                        <h6 class="panel-title-edit mbr-semibold mbr-fonts-style mb-0 display-5">
                          What's our goal?
                        </h6>
                        <span class="sign mbr-iconfont mobi-mbri-arrow-down"></span>
                      </a>
                    </div>
                    <div
                      id="collapse4_5"
                      class="panel-collapse noScroll collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                      data-bs-parent="#bootstrap-accordion_5"
                    >
                      <div class="panel-body">
                        <p class="mbr-fonts-style panel-text display-7">
                          To make fitness addictive and transform beta customers
                          into fitness fanatics!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" role="tab" id="headingOne">
                      <a
                        role="button"
                        class="panel-title collapsed"
                        data-toggle="collapse"
                        data-bs-toggle="collapse"
                        data-core=""
                        href="#collapse5_5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        <h6 class="panel-title-edit mbr-semibold mbr-fonts-style mb-0 display-5">
                          Ready to join?
                        </h6>
                        <span class="sign mbr-iconfont mobi-mbri-arrow-down"></span>
                      </a>
                    </div>
                    <div
                      id="collapse5_5"
                      class="panel-collapse noScroll collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                      data-bs-parent="#bootstrap-accordion_5"
                    >
                      <div class="panel-body">
                        <p class="mbr-fonts-style panel-text display-7">
                          Jump on board and let's make sweating fun again!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section
        data-bs-version="5.1"
        class="clients04 cid-ubtcR6XzdG"
        id="partners-1-ubtcR6XzdG"
      >
        <div class="container-fluid">
          <div class="row justify-content-center mb-5">
            <div class="col-12 content-head">
              <div class="mbr-section-head">
                <h4 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                  <strong>Join MoreMonths Now!</strong>
                </h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item features-image col-12 col-md-6 col-sm-6 col-lg-2 active">
              <div class="item-wrapper">
                <div class="">
                  <img
                    src="assets/images/photo-1617727553252-65863c156eb0.jpeg"
                    alt="Mobirise Website Builder"
                    title=""
                    data-slide-to="1"
                    data-bs-slide-to="1"
                  />
                </div>
              </div>
            </div>
            <div class="item features-image col-12 col-md-6 col-sm-6 col-lg-2">
              <div class="item-wrapper">
                <div class="">
                  <img
                    src="assets/images/photo-1606136968306-ab2868cc1f21.jpeg"
                    alt="Mobirise Website Builder"
                    title=""
                    data-slide-to="2"
                    data-bs-slide-to="2"
                  />
                </div>
              </div>
            </div>
            <div class="item features-image col-12 col-md-6 col-sm-6 col-lg-2">
              <div class="item-wrapper">
                <div class="">
                  <img
                    src="assets/images/photo-1646825461394-ebd1800141d1.jpeg"
                    alt="Mobirise Website Builder"
                    title=""
                    data-slide-to="3"
                    data-bs-slide-to="3"
                  />
                </div>
              </div>
            </div>
            <div class="item features-image col-12 col-md-6 col-sm-6 col-lg-2">
              <div class="item-wrapper">
                <div class="">
                  <img
                    src="assets/images/photo-1554463529-e27854014799.jpeg"
                    alt="Mobirise Website Builder"
                    title=""
                    data-slide-to="4"
                    data-bs-slide-to="4"
                  />
                </div>
              </div>
            </div>
            <div class="item features-image col-12 col-md-6 col-sm-6 col-lg-2">
              <div class="item-wrapper">
                <div class="">
                  <img
                    src="assets/images/photo-1681415851723-dca11d0ec9a6.jpeg"
                    alt="Mobirise Website Builder"
                    title=""
                    data-slide-to="5"
                    data-bs-slide-to="5"
                  />
                </div>
              </div>
            </div>
            <div class="item features-image col-12 col-md-6 col-sm-6 col-lg-2">
              <div class="item-wrapper">
                <div class="">
                  <img
                    src="assets/images/photo-1651044450619-5ce74d316987.jpeg"
                    alt="Mobirise Website Builder"
                    title=""
                    data-slide-to="6"
                    data-bs-slide-to="6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section
        data-bs-version="5.1"
        class="form5 cid-ubtcR6XRYA"
        id="contact-form"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 content-head">
              <div class="mbr-section-head mb-5">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                  <strong>Ready to increase retention with MoreMonths?</strong>
                </h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
              <form
                onSubmit={sendEmail}
                class="mbr-form form-with-styler"
                data-form-title="Form Name"
              >
                <input
                  type="hidden"
                  name="email"
                  data-form-email="true"
                  value="E3xXNfLYt66hhFdy6z9xP1zKQtswRI/Y3seda8n+Gk4GLco6UO+O4olf+diO+jpRcPoGjS0E2yF4P18/VBg+QSEc/k/jwiNCMeDqIxvjlzKPqVegm7bMLoFANSs8BLJK"
                />
                <div class="row">
                  <div
                    hidden="hidden"
                    data-form-alert=""
                    class="alert alert-success col-12"
                  >
                    Thanks for filling out the form!
                  </div>
                  <div
                    hidden="hidden"
                    data-form-alert-danger=""
                    class="alert alert-danger col-12"
                  >
                    Oops...! some problem!
                  </div>
                </div>
                <div class="dragArea row">
                  <div class="col-md col-sm-12 form-group mb-3" data-for="name">
                    <input
                      type="text"
                      placeholder="Name"
                      name="from_name"
                      class="form-control"
                      value={formState.from_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div
                    class="col-md col-sm-12 form-group mb-3"
                    data-for="email"
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      name="from_email"
                      class="form-control"
                      value={formState.from_email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  {/* <div class="col-12 form-group mb-3 mb-3" data-for="url">
                    <input
                      type="url"
                      name="url"
                      placeholder="Phone"
                      data-form-field="url"
                      class="form-control"
                      value=""
                      id="url-contact-form-3-ubtcR6XRYA"
                    />
                  </div> */}
                  <div class="col-12 form-group mb-3" data-for="textarea">
                    <textarea
                      name="message"
                      placeholder="Message"
                      class="form-control"
                      value={formState.message}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 align-center mbr-section-btn">
                    <button type="submit" class="btn btn-primary display-7">
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section
        data-bs-version="5.1"
        class="social4 cid-ubtcR6WMTA"
        id="follow-us-1-ubtcR6WMTA"
      >
        <div class="container">
          <div class="media-container-row">
            <div class="col-12">
              <h3 class="mbr-section-title align-center mb-5 mbr-fonts-style display-2">
                <strong>Stay Connected with Us</strong>
              </h3>
              <div class="social-list align-center">
                <a
                  class="iconfont-wrapper bg-facebook m-2 "
                  target="_blank"
                  rel="noreferrer"
                  href="fb://facebook.com/getmoremonths/"
                >
                  <span class="socicon-facebook socicon"></span>
                </a>
                {/* <a
                  class="iconfont-wrapper bg-twitter m-2"
                  href="https://mobiri.se"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="socicon-twitter socicon"></span>
                </a> */}
                <a
                  class="iconfont-wrapper bg-instagram m-2"
                  href="https://www.instagram.com/more_months/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="socicon-instagram socicon"></span>
                </a>

                {/* <a
                  class="iconfont-wrapper bg-tiktok m-2"
                  href="https://mobiri.se"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="socicon-tiktok socicon"></span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        data-bs-version="5.1"
        class="contacts01 cid-ubtcR6XSyp"
        id="contacts-1-ubtcR6XSyp"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 content-head">
              <div class="mbr-section-head mb-5">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                  <strong>Contact Us</strong>
                </h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item features-without-image col-12 col-md-6">
              <div class="item-wrapper">
                <div class="text-wrapper">
                  <h6 class="card-title mbr-fonts-style mb-3 display-5">
                    <strong>Email</strong>
                  </h6>
                  <p class="mbr-text mbr-fonts-style display-7"></p>
                  <p>
                    <a href="mailto:info@getmoremonths.com" class="text-black">
                      info@getmoremonths.com
                    </a>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MainContent;
